/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React from 'react';
import { Link } from 'gatsby';

// Style imports
import './Header.scss';

const Header = () => {
    return (
        <header className='header'>
            <Link className='header__image-link' to='/'>
                <span className='accessible'>Homepage</span>
            </Link>

            <nav className='header__container' aria-label='Main website navigation'>
                <ul className='header__list' role='menu'>
                    <li className='header__list-item' role='none'>
                        <Link className='header__link' to='/' role='menuitem'>Web Development</Link>
                    </li>

                    <li className='header__list-item' role='none'>
                        <Link className='header__link' to='/accessibility' role='menuitem'>Accessibility</Link>
                    </li>

                    <li className='header__list-item' role='none'>
                        <Link className='header__link' to='/design' role='menuitem'>UX/UI Design</Link>
                    </li>

                    <li className='header__list-item' role='none'>
                        <Link className='header__link' to='/blog' role='menuitem'>Blog</Link>
                    </li>
                </ul>
            </nav>
        </header>
    );
}

export default Header;