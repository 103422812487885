import React from "react";
import PropTypes from 'prop-types';

// Style Imports
import './Circle.scss';

const Circle = ({
    variant,
    thickness,
    color,
    height,
    width,
    top,
    right,
    bottom,
    left,
    opacity,
    desktop,
    mobile,
    id,
}) => {
    // Class definitions
    const baseClass = 'circle';
    const variantClass = variant ? `circle--${variant}` : '';
    const desktopClass = desktop ? 'circle--desktop' : '';
    const mobileClass = mobile ? 'circle--mobile' : '';
    const classes = [baseClass, variantClass, desktopClass, mobileClass].filter(Boolean).join(' ');

    const style = {
        border: variant !== 'gradient' ? `${thickness}px solid ${color}` : undefined,
        top: top,
        right: right,
        bottom: bottom,
        left: left,
        height: height,
        width: width,
        opacity,
    };

    return (
        <span
            className={classes}
            style={style}
            id={id}
        >
        </span>
    );
};

Circle.defaultProps = {
    thickness: 20,
    color: '#fff',
    opacity: 1,
};

Circle.propTypes = {
    variant: PropTypes.oneOf(['gradient']),
    thickness: PropTypes.number,
    color: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number,
    top: PropTypes.number,
    right: PropTypes.number,
    bottom: PropTypes.number,
    left: PropTypes.number,
    opacity: PropTypes.number,
    desktop: PropTypes.bool,
    mobile: PropTypes.bool,
    id: PropTypes.string,
};

export default Circle;