import React from "react";
import PropTypes from "prop-types";

// Component Imports
import Header from '../../components/shared/Header/Header.jsx';
import Testimonials from '../../components/shared/Testimonials/Testimonials.jsx';
import Footer from '../../components/shared/Footer/Footer.jsx';
import CookieBanner from "../../components/shared/CookieBanner/CookieBanner.jsx";
import BackToTop from "../../components/shared/BackToTop/BackToTop.jsx";

// Style Imports
import "./Default.scss";

const Default = ({
  children,
}) => {
  return (
    <div className='body-wrapper'>
      <Header />
      
      <main>
        {children}
      </main>

      <Testimonials />

      <Footer />

      <CookieBanner />

      <BackToTop />
    </div>
  );
};

Default.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Default;