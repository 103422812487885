import React from 'react';

// Component imports
import Carousel from '../Carousel/Carousel.jsx';

// Asset imports
import ReactIcon from '../../../assets/images/icons/react.svg';
import CodeIcon from '../../../assets/images/icons/code.svg';
import AccessibilityIcon from '../../../assets/images/icons/accessibility.svg';

// Style imports
import './Testimonials.scss';

const Testimonials = () => {
    return (
        <section className='testimonials'>
            <div className='testimonials__container container'>
                <h2 className='testimonials__heading'>What other people say</h2>
                <p className='testimonials__description'>Other peoples’ opinions matter. I have worked with many people over the years and think it is important to share their experience of working with me.</p>

                <div className='testimonials__inner-wrapper'>
                    <div className='testimonials__inner'>
                        <Carousel />
                    </div>

                    <div className='testimonials__inner'>
                        <ul className='testimonials__list'>
                            <li className='testimonials__list-item'>
                                <div className='testimonials__image-wrapper'>
                                    <img className='testimonials__image' src={ReactIcon} alt='' aria-hidden='true' />
                                </div>

                                <div className='testimonials__content'>
                                    <h3 className='testimonials__subheading'>React Development</h3>
                                    <p className='testimonials__text'>Tried and tested React application development. From super-fast single page applications to efficient large-scale corporate websites.</p>
                                </div>
                            </li>

                            <li className='testimonials__list-item'>
                                <div className='testimonials__image-wrapper'>
                                    <img className='testimonials__image' src={CodeIcon} alt='' aria-hidden='true' />
                                </div>

                                <div className='testimonials__content'>
                                    <h3 className='testimonials__subheading'>High-quality Templating</h3>
                                    <p className='testimonials__text'>Experienced approach to coding consistent and maintainable frontend templates and component libraries.</p>
                                </div>
                            </li>

                            <li className='testimonials__list-item'>
                                <div className='testimonials__image-wrapper'>
                                    <img className='testimonials__image' src={AccessibilityIcon} alt='' aria-hidden='true' />
                                </div>

                                <div className='testimonials__content'>
                                    <h3 className='testimonials__subheading'>Accessibility Implementation</h3>
                                    <p className='testimonials__text'>Deep understanding of website accessibility, accessibility consultation, practical design, development, and testing experience.</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Testimonials;