import React, { useState, useRef } from 'react';
import emailjs from 'emailjs-com';
import { useForm } from 'react-hook-form';

// Component Imports
import Loader from '../Loader/Loader.jsx';
import Toast from '../Toast/Toast.jsx';
import ContactField from '../ContactField/ContactField';

// Style Imports
import './ContactForm.scss';

const ContactForm = () => {
    const [state, setState] = useState([{
        name: '',
        email: '',
        subject: '',
        organisation: '',
        message: '',
        disableForm: false,
        formSubmitted: false,
        formError: false,
    }]);

    const { register, handleSubmit, errors } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
    });

    const formRef = useRef(null);
        
    function sendEmail() {
        setState({...state, disableForm: true});
        
        let templateParams = {
            from_name: state.email,
            to_name: 'james_parker_gmail',
            name: state.name,
            organisation: state.organisation,
            subject: state.subject,
            message: state.message,
        }

        emailjs.send('james_parker_gmail', 'james_parker_gmail', templateParams, 'user_SuOp1XvItI850LGO5kBSu')
        .then(() => {
            setState(
            {
                ...state,
                formError: false,
                formSubmitted: true,
                disableForm: false,
            }
            );
        }, () => {
            setState(
                {
                    ...state,
                    formError: true,
                    formSubmitted: false,
                    disableForm: false,
                }
            );
        }); 
    };

    return (
        <form
            className='contact-form'
            onSubmit={handleSubmit(sendEmail)}
            noValidate
            ref={formRef}
        >
            <h2 className='contact-form__heading'>Send a message</h2>
            <p className='contact-form__description'>If you want to find out more about my skills and availability, or any of the services mentioned on this site, please send a message with the contact form below and I’ll get back to you as soon as possible.</p>

            <div className='contact-form__inner'>
                <ContactField
                    labelText='Full name:'
                    id='full-name'
                    type='text'
                    onChange={(event) => setState({...state, name: event.target.value})}
                    ref={register({required: true})}
                    hasError={!!errors['full-name']}
                    required
                    disabled={state.disableForm ? true : false}
                    maxLength={150}
                />

                <ContactField
                    labelText='Email address:'
                    id='email-address'
                    type='email'
                    onChange={(event) => setState({...state, email: event.target.value})}
                    ref={register({required: true, pattern: /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/})}
                    hasError={!!errors['email-address']}
                    required
                    disabled={state.disableForm ? true : false}
                    maxLength={150}
                />

                <ContactField
                    labelText='Subject:'
                    id='subject'
                    type='text'
                    onChange={(event) => setState({...state, subject: event.target.value})}
                    ref={register({required: true})}
                    hasError={!!errors['subject']}
                    required
                    disabled={state.disableForm ? true : false}
                    maxLength={100}
                />

                <ContactField
                    labelText='Organisation name:'
                    id='organisation-name'
                    type='text'
                    onChange={(event) => setState({...state, organisation: event.target.value})}
                    disabled={state.disableForm ? true : false}
                    maxLength={100}
                />

                <ContactField
                    labelText='Message:'
                    id='message'
                    type='text'
                    onChange={(event) => setState({...state, message: event.target.value})}
                    ref={register({required: true})}
                    hasError={!!errors['message']}
                    required
                    disabled={state.disableForm ? true : false}
                    variant='textarea'
                />
            </div>

            <input
                className='button contact-form__submit'
                type='submit'
                value='Submit'
                disabled={state.disableForm ? true : false}
            />

            { state.disableForm && (
                <Loader />
            )}

            {(state.formSubmitted || state.formError) && (
                <Toast variant={state.formError ? 'error' : ''} />
            )}
        </form>
    );
};

export default ContactForm;