import React, { useState } from 'react';
import { Link } from "gatsby";

// Component Imports
import Button from '../Button/Button.jsx';
import Modal from '../Modal/Modal.jsx';
import ContactForm from '../ContactForm/ContactForm.jsx';
import Circle from '../Circle/Circle.jsx';

// Style imports
import './Footer.scss';

const Footer = () => {
    const [modalOpen, setModalOpen] = useState(false);

    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    return (
        <footer className='footer'>
            <div className='footer__inner'>
                <div className='footer__container container'>
                    <h2 className='footer__heading'>Get in touch today</h2>

                    <ul className='footer__list'>
                        <li className='footer__list-item'>
                            <Link className='footer__link' to="/">Web Development</Link>
                        </li>

                        <li className='footer__list-item'>
                            <Link className='footer__link' to="/accessibility">Accessibility</Link>
                        </li>

                        <li className='footer__list-item'>
                            <Link className='footer__link' to="/design">UX/UI Design</Link>
                        </li>

                        <li className='footer__list-item'>
                            <Link className='footer__link' to="/blog">Blog</Link>
                        </li>
                    </ul>

                    <div className='footer__button-wrapper'>
                        <Button
                            variant='hover-outline'
                            onClick={(event) => {
                                event.preventDefault();
                                openModal();
                            }}
                        >
                            Contact me
                            <span className='accessible'> Opens modal window</span>
                        </Button>

                        <a className='button button--icon footer__linkedin' href='https://www.linkedin.com/in/james-parker-194a4574/'>
                            <span className='accessible'>Linkedin profile</span>
                        </a>

                        <a className='button button--icon footer__github' href='https://github.com/ParkyGames'>
                            <span className='accessible'>GitHub profile</span>
                        </a>
                    </div>
                </div>
                
                {/* Desktop Circles */}
                <Circle
                    height={221}
                    width={221}
                    bottom={188}
                    right={-136}
                    opacity={0.25}
                    desktop
                />

                <Circle
                    height={575}
                    width={575}
                    bottom={-273}
                    right={-270}
                    opacity={0.1}
                    desktop
                />

                <Circle
                    height={535}
                    width={535}
                    bottom={-273}
                    right={-270}
                    opacity={0.1}
                    thickness={40}
                    desktop
                />

                {/* Mobile Circles */}

                <Circle
                    height={126}
                    width={126}
                    bottom={157}
                    right={-85}
                    opacity={0.25}
                    thickness={15}
                    mobile
                />

                <Circle
                    height={305}
                    width={305}
                    bottom={-110}
                    right={-160}
                    opacity={0.1}
                    thickness={15}
                    mobile
                />

                <Circle
                    height={275}
                    width={275}
                    bottom={-110}
                    right={-160}
                    opacity={0.1}
                    thickness={30}
                    mobile
                />
            </div>

            <div className='footer__inner'>
                <div className='footer__container container'>
                    <ul className='footer__disclaimer-list'>
                        <li className='footer__disclaimer-item'>
                            <small className='footer__disclaimer'>&copy;2020-{new Date().getFullYear()} JP Development - All rights reserved</small>
                        </li>
                        <li className='footer__disclaimer-item'>
                            <Link className='footer__disclaimer-link' to="/terms">Terms</Link>
                        </li>
                        <li className='footer__disclaimer-item'>
                            <Link className='footer__disclaimer-link' to="/privacy">Privacy</Link>
                        </li>
                        <li className='footer__disclaimer-item'>
                            <Link className='footer__disclaimer-link' to="/cookies">Cookies</Link>
                        </li>
                    </ul>
                </div>
            </div>

            {modalOpen && (
                <Modal
                    modalOpen={modalOpen}
                    onRequestClose={closeModal}
                    modalLabel='Contact modal'
                >
                    <ContactForm />
                </Modal>
            )}
        </footer>
    );
}

export default Footer;