import React from "react";

// Style Imports
import './Loader.scss';

// Asset Imports
import Loading from '../../../assets/images/icons/loading.svg';

const Loader = () => {
    return (
        <div className='loader'>
            <img className='loader__image' src={Loading} alt='' aria-hidden='true' />
            <p className='loader__text'>Sending message...</p>
        </div>
    );
};

export default Loader;