import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import Modal from 'react-modal';

// Component Imports
import Circle from '../Circle/Circle.jsx';

// Style Imports
import './Modal.scss';

Modal.setAppElement('#___gatsby');

const ModalWindow = ({
    children,
    modalOpen,
    onRequestClose,
    modalLabel,
}) => {
    const [animated, setAnimated] = useState(false);

    // Modal Styles
    const modalStyles = {
        overlay: {
            zIndex: '10',
            backgroundColor: animated ? 'rgba(0, 0, 0, .85)' : 'rgba(0, 0, 0, 0)',
            transition: 'background-color ease .75s',
        },
    };

    const handleAnimation = () => {
        setTimeout(function() {
            setAnimated(true);
        }, 0);
    };

    useEffect(() => {
        handleAnimation();
    }, []);
    
    return (
        <Modal
            className={`modal${animated ? ' modal--animated' : ''}`}
            isOpen={modalOpen}
            onRequestClose={onRequestClose}
            style={modalStyles}
            contentLabel={modalLabel}
        >
            <button className='modal__button' onClick={onRequestClose}>
                <span className='accessible'>Close modal overlay</span>
            </button>
            
            <div className='modal__content'>
                {children}

                {/* Desktop Circles */}
                <Circle
                    color='#7E8588'
                    height={421}
                    width={421}
                    top={-84}
                    left={-176}
                    opacity={0.1}
                    desktop
                />

                <Circle
                    color='#7E8588'
                    height={381}
                    width={381}
                    top={-84}
                    left={-176}
                    opacity={0.1}
                    thickness={40}
                    desktop
                />

                <Circle
                    color='#7E8588'
                    height={248}
                    width={248}
                    top={167}
                    left={66}
                    opacity={0.05}
                    thickness={30}
                    desktop
                />

                {/* Mobile Circles */}
                <Circle
                    color='#7E8588'
                    height={421}
                    width={421}
                    top={-215}
                    left={-285}
                    opacity={0.1}
                    thickness={15}
                    mobile
                />

                <Circle
                    color='#7E8588'
                    height={391}
                    width={391}
                    top={-215}
                    left={-285}
                    opacity={0.1}
                    thickness={30}
                    mobile
                />

                <Circle
                    color='#7E8588'
                    height={248}
                    width={248}
                    top={60}
                    left={-75}
                    opacity={0.05}
                    thickness={20}
                    mobile
                />
            </div>
        </Modal>
    );
};

ModalWindow.propTypes = {
    children: PropTypes.node.isRequired,
    modalOpen: PropTypes.bool.isRequired,
    modalLabel: PropTypes.string.isRequired,
    onRequestClose: PropTypes.func.isRequired,
};

export default ModalWindow;