import React from 'react';
import Slider from "react-slick";

// Asset imports
import LukeCanvin from '../../../assets/images/content/luke-canvin.jpg';
import SteveDenning from '../../../assets/images/content/steve-denning.jpg';
import PaulSandwell from '../../../assets/images/content/paul-sandwell.jpg';
import NickMustoe from '../../../assets/images/content/nick-mustoe.jpg';
import AlexDeredas from '../../../assets/images/content/alex-deredas.jpg';
import JeffEng from '../../../assets/images/content/jeff-eng.jpg';

// Carousel styles
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Style imports
import './Carousel.scss';

const Carousel = () => {
    var settings = {
        accessibility: true,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 7500,
        slidesToScroll: 1,
        className: 'carousel',
        fade: true,
    };

    return (
        <Slider {...settings}>
            <article className='carousel__article'>
                <h3 className='accessible'>Testimonial - Luke Canvin</h3>
                <q className='carousel__quote'>James is a passionate and professional developer with a keen attention to detail and deep understanding of his field. His frontend experience, clear guidance, and knowledge of accessibility guidelines and best practices have proven extremely valuable.</q>

                <div className='carousel__container'>
                    <div className='carousel__image-wrapper'>
                        <img className='carousel__image' src={LukeCanvin} alt='' aria-hidden='true' />
                    </div>

                    <div className='carousel__content'>
                        <p className='carousel__author'>Luke Canvin</p>
                        <p className='carousel__job'>Product Manager, Oxford Computer Consultants</p>
                    </div>
                </div>
            </article>

            <article className='carousel__article'>
                <h3 className='accessible'>Testimonial - Steve Denning</h3>
                <q className='carousel__quote'>James has always been a safe bet to resolve issues surrounding projects, even lending a helping hand to other areas to widen his skillset and learn new technologies. I would have no doubt in recommending James for a position, an absolute pleasure to work with.</q>

                <div className='carousel__container'>
                    <div className='carousel__image-wrapper'>
                        <img className='carousel__image' src={SteveDenning} alt='' aria-hidden='true' />
                    </div>

                    <div className='carousel__content'>
                        <p className='carousel__author'>Steve Denning</p>
                        <p className='carousel__job'>Senior React Developer, This is ONE</p>
                    </div>
                </div>
            </article>

            <article className='carousel__article'>
                <h3 className='accessible'>Testimonial - Paul Sandwell</h3>
                <q className='carousel__quote'>James is never afraid to try new approaches and is always eager to learn new techniques in the ever-changing world of web development. He is a valued asset to the team, and I am always keen to have him involved on my projects.</q>

                <div className='carousel__container'>
                    <div className='carousel__image-wrapper'>
                        <img className='carousel__image' src={PaulSandwell} alt='' aria-hidden='true' />
                    </div>

                    <div className='carousel__content'>
                        <p className='carousel__author'>Paul Sandwell</p>
                        <p className='carousel__job'>Software Engineer, CGI</p>
                    </div>
                </div>
            </article>

            <article className='carousel__article'>
                <h3 className='accessible'>Testimonial - Nick Mustoe</h3>
                <q className='carousel__quote'>Eager to get involved and quick to pick up where others had left off. James has a great eye for detail. I'd happily work with James again and wouldn't hesitate to recommend him.</q>

                <div className='carousel__container'>
                    <div className='carousel__image-wrapper'>
                        <img className='carousel__image' src={NickMustoe} alt='' aria-hidden='true' />
                    </div>

                    <div className='carousel__content'>
                        <p className='carousel__author'>Nick Mustoe</p>
                        <p className='carousel__job'>Senior Project Manager, PharmiWeb Solutions</p>
                    </div>
                </div>
            </article>

            <article className='carousel__article'>
                <h3 className='accessible'>Testimonial - Alex Deredas</h3>
                <q className='carousel__quote'>Working with James for over 5 years in this area, he has shown great determination and is always willing to produce new fresh ideas. With a keen eye for detail and the ability to improve on key areas I would highly recommend him.</q>

                <div className='carousel__container'>
                    <div className='carousel__image-wrapper'>
                        <img className='carousel__image' src={AlexDeredas} alt='' aria-hidden='true' />
                    </div>

                    <div className='carousel__content'>
                        <p className='carousel__author'>Alex Deredas</p>
                        <p className='carousel__job'>Senior Technical Analyst, Gresham Tech</p>
                    </div>
                </div>
            </article>

            <article className='carousel__article'>
            <h3 className='accessible'>Testimonial - Jeff Eng</h3>
                <q className='carousel__quote'>James was dedicated and was eager for a challenge, especially his outside the box attitude. Not only did he gain the insight and know-how but applied and passed the knowledge to others within the team. I regarded James as an important asset to team accomplishment.</q>

                <div className='carousel__container'>
                    <div className='carousel__image-wrapper'>
                        <img className='carousel__image' src={JeffEng} alt='' aria-hidden='true' />
                    </div>

                    <div className='carousel__content'>
                        <p className='carousel__author'>Jeff Eng</p>
                        <p className='carousel__job'>Technical digital professional</p>
                    </div>
                </div>
            </article>
        </Slider>
    );
}

export default Carousel;