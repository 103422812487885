import React from 'react';
import PropTypes from 'prop-types';

// Style Imports
import './Button.scss';

const Button = ({
    className,
    variant,
    id,
    type,
    disabled,
    onClick,
    children,
    ariaControls,
    ariaExpanded,
    role,
}) => {
    // Class definitions
    const baseClass = 'button';
    const variantClass = variant ? `button--${variant}` : '';
    const disabledClass = disabled ? 'button--disabled' : '';
    const customClass = className;
    const classes = [baseClass, variantClass, disabledClass, customClass].filter(Boolean).join(' ');

    return (
        <button
            className={classes}
            id={id}
            type={type}
            disabled={disabled || ''}
            aria-disabled={disabled || undefined}
            onClick={onClick}
            aria-controls={ariaControls}
            aria-expanded={ariaExpanded}
            role={role}
        >
            {children}
        </button>
    );
};

Button.propTypes = {
    className: PropTypes.string,
    variant: PropTypes.oneOf(['hover-outline', 'icon']),
    id: PropTypes.string,
    type: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    children: PropTypes.node.isRequired,
    ariaControls: PropTypes.string,
    ariaExpanded: PropTypes.bool,
    role: PropTypes.string,
};

export default Button;