import React, { useState, useEffect } from 'react';
import CookieConsent from "react-cookie-consent";

// Style Imports
import './CookieBanner.scss';

const CookieBanner = () => {
    const [opacity, setOpacity] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            setOpacity(1);
        }, 1500);
    }, []);

    return (
        <section className='cookie-banner' style={{'opacity': opacity}}>
            <h2 className='accessible'>Cookie policy popup</h2>
            <CookieConsent
                containerClasses='cookie-banner__inner'
                buttonWrapperClasses='cookie-banner__buttons'
                buttonClasses='cookie-banner__button'
                contentClasses='cookie-banner__content'
                disableStyles={true}
                location="bottom"
                buttonText="Accept"
                cookieName="gatsby-gdpr-google-analytics"
            >
                This site uses cookies to collect information and analytics about how you interact with pages to help improve your browsing experience.
            </CookieConsent>
        </section>
    );
};

export default CookieBanner;